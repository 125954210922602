import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  ILocation, IMapImage,
  IResultDetail,
  IResultsList,
  IResultsQueryParams, ISearchNeighborhood
} from '@results/shared/interfaces/results.interface';
import { IFilterConfig } from '@results/shared/interfaces/filter-config.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResultsService {
  private boundsSubject = new BehaviorSubject<{
    top_left?: { lat: number; lng: number };
    bottom_right?: { lat: number; lng: number }
  }>({});
  public bounds = this.boundsSubject.asObservable();

  constructor(private _httpClient: HttpClient) {
  }

  updateBounds(updatedField: {
    top_left?: { lat: number; lng: number };
    bottom_right?: { lat: number; lng: number }
  }): void {
    const currentValue = this.boundsSubject.getValue();
    this.boundsSubject.next({...currentValue, ...updatedField});
  }

  getFilter(params: string) {
    return this._httpClient.get<IResultsList>(`main/properties/api/v1/list/?${params}`);
  }

  getLocation(id: string) {
    return this._httpClient.get<ILocation>(`main/properties/locations/${id}/`);
  }

  getFilterConfig() {
    return this._httpClient.get<IFilterConfig>(`main/filter-configs/`);
  }

  getResultDetail(pid: string) {
    return this._httpClient.get<IResultDetail>(`main/properties/api/v1/detail/${pid}/`);
  }

  searchNeighborhood(data: string) {
    return this._httpClient.get<ISearchNeighborhood>(`main/properties/search?term=${data}`);
  }

  getMapImage(pid: string) {
    return this._httpClient.get<IMapImage>(`main/properties/api/v1/map/detail/${pid}`);
  }
}
